
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MiniDocsTile",

  setup() {
    const store = useStore();
    const router = useRouter();

    const unseenCount = computed(() => store.getters["student-document/getUnseenCount"]);

    const onClick = () => {
      router.push({ name: "Docs" });
    };

    return {
      unseenCount,
      onClick,
    };
  },
});
