
import { Options, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import TheoryBlock from "@/components/Pages/Home/Theory/TheoryBlock.vue";
import Appointments from "@/components/Pages/Home/Appointments.vue";
import AccountBlock from "@/components/Pages/Home/Account/AccountBlock.vue";
import Education from "@/components/Pages/Home/Education.vue";
import { mixins } from "vue-class-component";
import StudentEducationsStoreMixin from "@/mixins/StudentEducations/StudentEducationsStoreMixin.ts";
import { namespace } from "s-vuex-class";
import ModalMixin from "@/mixins/ModalMixin.ts";
import TrainingContractBlock from "@/views/Onboarding/TrainingContract/TrainingContractBlock.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import UserMixin from "@/mixins/UserMixin";
import contractHasNotApproved from "@/helper/contractHasNotApproved";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IExpiredTheoryQuestion } from "@/interfaces/IExpiredTheoryQuestion";
import CloseToExpireTheoryQuestion from "@/components/Pages/Home/CloseToExpireTheoryQuestionVersion.vue";
import ExpiredTheoryQuestionVersion from "@/components/Pages/Home/ExpiredTheoryQuestionVersion.vue";
import GocardlessModal from "@/components/Modals/GocardlessModal.vue";
import MiniNewsTile from "@/components/Pages/Home/MiniNewsTile.vue";
import MiniDocsTile from "@/components/Pages/Home/MiniDocsTile.vue";
import BasicKontoAufladen from "@/components/Pages/Home/Account/BasicKontoAufladen.vue";
import StorageService from "@/services/StorageService";
import moment from "moment";
import AbibaroStartBlock from "@/views/Abibaro/AbibaroStartBlock.vue";
import Loading from "@/views/Home/Loading.vue";
import MyDrivingSchool from "@/components/Pages/Home/MyDrivingSchool.vue";

const TheoryQuestionsVersionModule = namespace("theory-question-version");
const StudentEducationModule = namespace("student-education");
const GocardlessModule = namespace("gocardless");
const AbibaroModule = namespace("abibaro");
const DrivingSchoolModule = namespace("driving-school");

@Options({
  components: {
    Loading,
    BasicKontoAufladen,
    MiniDocsTile,
    MiniNewsTile,
    ExpiredTheoryQuestionVersion,
    CloseToExpireTheoryQuestion,
    Avatar,
    TrainingContractBlock,
    Education,
    AccountBlock,
    Appointments,
    TheoryBlock,
    DefaultLayout,
    GocardlessModal,
    AbibaroStartBlock,
    MyDrivingSchool,
  },
})
export default class Home extends mixins(StudentEducationsStoreMixin, ModalMixin, UserMixin) {
  public name = "Home";

  private hasCloseToExpiryVersions = false;

  // Account block education tabs
  @StudentEducationModule.Getter("getSelectedAccountStudentEducation")
  public selectedEducation: any;

  @GocardlessModule.Getter("getGocardless")
  public getGocardless!: boolean;

  @GocardlessModule.Action("setGocardless")
  public setGocardless!: (payload: boolean) => void;

  @TheoryQuestionsVersionModule.Action("expiredQuestion/findOne")
  public checkExpiredTheoryQuestion!: (opt: ICrudOptions) => Promise<void>;

  @TheoryQuestionsVersionModule.Getter("expiredQuestion/getDataItem")
  public expiredTheoryQuestion!: IExpiredTheoryQuestion | any;

  @TheoryQuestionsVersionModule.Getter("expiredQuestion/getIsLoading")
  public expiredTheoryQuestionLoading!: boolean;

  @TheoryQuestionsVersionModule.Action("create")
  public setTheoryQuestionsVersionAction!: (opts: ICrudOptions) => Promise<void>;

  @TheoryQuestionsVersionModule.Action("update")
  public updateTheoryQuestionsVersionAction!: (opts: ICrudOptions) => Promise<void>;

  @TheoryQuestionsVersionModule.Getter("getSuccess")
  public success: any;

  // Modal balance showing
  @AbibaroModule.Action("findOne")
  public findAbibaroEducations: any;

  @AbibaroModule.Getter("getDataItem")
  public abibaroEducations: any;

  @DrivingSchoolModule.Action("findMyDrivingSchoolConfig")
  public findMyDrivingSchoolConfig: any;

  @DrivingSchoolModule.Getter("getMyDrivingSchoolConfig")
  public myDrivingSchoolConfig: any;

  private get livePayStudentEducations(): Array<any> {
    return this.studentEducations.filter((se: any) => se.activeLivePayMandate && se.activeLivePayMandateId);
  }

  private findAbibaroByStudentId(studentId: any): void {
    this.findAbibaroEducations({
      resource: "abibaro/qr-code-and-login-info-for-all-educations",
      id: studentId,
      hideSuccessMessage: true,
    });
  }

  public async mounted(): Promise<void> {
    this.findAbibaroByStudentId(this.currentUser.id);
    this.checkQuestionVersions();
    this.findMyDrivingSchoolConfig();
  }

  @Watch("livePayStudentEducations", { immediate: true })
  public onChangeStudentEducations(livePayStudentEducations: Array<any>) {
    if (this.getGocardless && livePayStudentEducations.length) {
      this.$nextTick(() => {
        this.gocardlessModal();
      });
    }
  }

  private missingQuestionStorageKey = "missing-question";

  private async checkQuestionVersions() {
    await this.checkExpiredTheoryQuestionFn();
  }

  public async checkExpiredTheoryQuestionFn() {
    this.hasCloseToExpiryVersions = false;
    await this.checkExpiredTheoryQuestion({
      resource: "/theory-question-versions/student/check-expired-questions-version",
      descriptionField: "",
    });

    if (this.expiredTheoryQuestion?.newVersion && this.expiredTheoryQuestion.newVersion.id) {
      this.hasCloseToExpiryVersions = true;
    }

    this.dispatchModals();
  }

  private dispatchModals() {
    if (this.hasCloseToExpiryVersions) {
      this.showModal(null, "closeToExpireTheoryVersionModal");
    }
  }

  public get hasStudentEducation(): boolean {
    return Boolean(this.filteredEducations.length);
  }

  public get visibleAbibaroBlock(): boolean {
    return Boolean(this.abibaroEducations.length);
  }

  protected get contractHasNotApproved() {
    return contractHasNotApproved();
  }

  public async setTheoryQuestionsVersion(selectedVersionId: number) {
    await this.setTheoryQuestionsVersionAction({
      resource: `theory-question-versions/select-version/${selectedVersionId}`,
      successMessage: this.$t("messages.set_theory_questions_version_success"),
      descriptionField: "",
      data: {
        // studentEducationId: null,
        theoryQuestionsVersionId: selectedVersionId,
      },
    });
    if (this.success === true) {
      this.hideModal(null, "closeToExpireTheoryVersionModal");
    }
  }

  public async onCloseCloseToExpireTheoryQuestion(versionId: number | null) {
    await this.updateTheoryQuestionsVersionAction({
      resource: `/theory-question-versions/wait-version/${versionId}`,
      descriptionField: "",
    });

    if (this.success === true) {
      this.hideModal(null, "closeToExpireTheoryVersionModal");
    }
  }

  public async setCloseToExpireTheoryQuestionsVersion(selectedVersionId: number) {
    await this.setTheoryQuestionsVersion(selectedVersionId);
    if (this.success === true) {
      this.hideModal(null, "closeToExpireTheoryVersionModal");
      await this.checkExpiredTheoryQuestionFn();
    }
  }

  private gocardlessModal(show = true) {
    if (show) {
      this.showModal(null, "gocardlessModal");
    } else {
      this.hideModal(null, "gocardlessModal");
    }
  }

  private gocardlessCopyAccountModal(show = true) {
    if (show) {
      this.showModal(null, "gocardlessCopyAccountModal");
    } else {
      this.hideModal(null, "gocardlessCopyAccountModal");
    }
  }

  private onClickMobileSignalHandle() {
    //this.gocardlessModal(false);
    //this.gocardlessCopyAccountModal();
    this.hideGocadless();
    this.onClickBasicAccountBlock();
  }

  private hideGocadless() {
    this.gocardlessModal(false);
    this.setGocardless(false);
  }

  private onClickBasicAccountBlock() {
    (this.$refs.accountBlock as AccountBlock).onShowLoadAccountForm();
  }

  public get filteredEducations() {
    return this.studentEducations?.filter((education: any) => education.theoryRequired) || [];
  }

  private get isLoading() {
    return this.loadingStudentEducations;
  }

  public get visibleMyDrivingSchoolBlock(): boolean {
    return Boolean(this.myDrivingSchoolConfig.visibleDriveBuzzMyDrivingSchoolLink);
  }

  public get myDrivingSchoolLink(): string {
    return String(this.myDrivingSchoolConfig.driveBuzzMyDrivingSchoolLink);
  }
}
