
import { computed, defineComponent, PropType, ref } from "vue";
import { IBasicNamedDto } from "@/interfaces/IBasicNamedDto";
import Validation from "@/components/Validation.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ITheoryQuestion } from "@/interfaces/IExpiredTheoryQuestion";

export default defineComponent({
  name: "ExpiredTheoryQuestionVersion",
  components: { Validation },
  props: {
    validReplacementVersions: {
      type: Array as PropType<Array<IBasicNamedDto>>,
      default: () => [],
    },
    expiredVersions: {
      type: Array as PropType<Array<ITheoryQuestion>>,
      default: () => [],
    },
  },
  emits: ["on-submit"],
  setup(props, { emit }) {
    const selectedVersionId = ref<number | null>(null);

    const v$ = useVuelidate(
      {
        selectedVersionId: { required },
      },
      {
        selectedVersionId,
      }
    );

    const onSubmit = () => {
      v$.value.$touch();
      if (v$.value.$invalid) return;
      emit("on-submit", expiredVersion.value ? expiredVersion.value.studentEducationId : null, selectedVersionId.value);
    };

    const expiredVersion = computed<ITheoryQuestion | null>(() => (props.expiredVersions?.length ? props.expiredVersions[0] : null));
    const expiredVersionName = computed<string | null>(() => (props.expiredVersions?.length ? props.expiredVersions[0].name : null));

    return {
      selectedVersionId,
      onSubmit,
      v$,
      expiredVersion,
      expiredVersionName,
    };
  },
});
