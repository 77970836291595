
import { Options, Prop, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import { nowIndex } from "@/helper/AppointementsHelper";
import { useIonRouter } from "@ionic/vue";

const AppointmentsModule = namespace("appointments");

@Options({
  components: {},
})
export default class Appointments extends Vue {
  private ionRouter = useIonRouter();

  @Prop({ default: () => false })
  public disabled!: boolean;

  @Prop({ default: () => false })
  public basicView!: boolean;

  @AppointmentsModule.Action("findAll")
  public findAppointmentsAction: any;

  @AppointmentsModule.Action("setNextAppointment")
  public setNextAppointmentAction: any;

  @AppointmentsModule.Getter("getDataList")
  public appointments: any;

  @AppointmentsModule.Getter("getIsLoading")
  public loading: any;

  @AppointmentsModule.Getter("getSuccess")
  public markSuccess: any;

  get countUnseenAppointments(): number {
    if (!this.appointments || !this.appointments.length) {
      return 0;
    }
    const unseen = this.appointments.filter((a: any) => !a.seen);
    return unseen.length;
  }

  get nextAppointment(): any {
    if (!this.appointments || !this.appointments.length) {
      return null;
    }
    const index = nowIndex(this.appointments);
    if (index < 1) {
      return null;
    }
    return this.appointments[index - 1];
  }

  public mounted(): void {
    this.load();
  }

  private load(): void {
    this.findAppointmentsAction({
      resource: "appointments/student/current",
      hideSuccessMessage: true,
    });
  }

  public onClickAppointmentCard(): void {
    this.ionRouter.navigate("/appointments", "forward", "push");
  }
}
