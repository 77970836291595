
import { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class Loading extends mixins() {
  public name = "Loading";

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;
}
